import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid, Carousel } from 'antd'

import * as styles from './residency.module.scss'

const ResidencyCarousel = props => {
    const { useBreakpoint } = Grid
    const { md, sm } = useBreakpoint()
    const [sliderItems, setSliderItems] = useState([])
    const { residencyTitle, residencyDescription, residencyLink, residencyItems } = props.data

    useEffect(() => {
        residencyItems.forEach((slide, index) => {
            const isGroup = index === 2 || index === 5 || index === 8

            setSliderItems(prevArray => {
                isGroup && prevArray[prevArray.length - 1].push(slide)

                return isGroup ? prevArray : [...prevArray, [slide]]
            })
        })
    }, [])

    return (
        <section className={styles.wrapper + ' global-full-width'}>
            <div className='global-container'>
                <h2 className='global-title-h2'>{residencyTitle}</h2>
                <p className='global-text'>{residencyDescription}</p>
                {md && residencyLink.url && (
                    <Link to={residencyLink.url} className='global-button global-button--rounded'>
                        {residencyLink.title}
                    </Link>
                )}
                {sm ? (
                    <Carousel
                        className={styles.slider}
                        arrows={true}
                        draggable={true}
                        slidesToShow={3}
                        slidesToScroll={1}
                        responsive={[
                            {
                                breakpoint: 991,
                                settings: {
                                    slidesToShow: 2,
                                },
                            },
                        ]}
                    >
                        {sliderItems.map(slide => {
                            const title = slide[0].residencySlideTitle
                            const description = slide[0].residencySlideDescription
                            const slidePage = slide[0].residencySlideLink

                            if (!slidePage) return

                            const flagCode = slidePage.countryFlagIcon?.flag
                                ? slidePage.countryFlagIcon.flag.split('.')[0]
                                : null
                            const flagImage = flagCode ? require(`../../assets/images/flags/${flagCode}.svg`) : null
                            const slideBigImage =
                                slidePage.featuredImage && slidePage.featuredImage.node
                                    ? getImage(slidePage.featuredImage.node.localFile)
                                    : null

                            return (
                                <div className={styles.slideInner} key={slidePage.id}>
                                    {slide.length > 1 ? (
                                        slide.map(innerSlide => {
                                            const slideTitle = innerSlide.residencySlideTitle
                                            const slideDescription = innerSlide.residencySlideDescription
                                            const innerSlidePage = innerSlide.residencySlideLink

                                            if (!innerSlidePage) return

                                            const flagCode = innerSlidePage.countryFlagIcon?.flag
                                                ? innerSlidePage.countryFlagIcon.flag.split('.')[0]
                                                : null
                                            const flagImage = flagCode
                                                ? require(`../../assets/images/flags/${flagCode}.svg`)
                                                : null
                                            const slideSmallImage =
                                                innerSlidePage.featuredImage && innerSlidePage.featuredImage.node
                                                    ? innerSlidePage.featuredImage.node.localFile.childrenImageSharp[0]
                                                          .gatsbyImageData
                                                    : null

                                            return (
                                                <Link
                                                    to={innerSlidePage.uri}
                                                    key={innerSlidePage.id}
                                                    className={styles.slideLink}
                                                >
                                                    <>
                                                        {slideSmallImage ? (
                                                            <GatsbyImage image={slideSmallImage} alt={slideTitle} />
                                                        ) : (
                                                            <img
                                                                alt='image placeholder'
                                                                role='presentation'
                                                                src="data:image/svg+xml;charset=utf-8,%3Csvg height='270' width='390' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                                            />
                                                        )}
                                                        <div className={styles.slideContentWrapper}>
                                                            <h3 className={styles.slideTitle}>
                                                                <span className={styles.flag}>
                                                                    <img
                                                                        src={flagImage.default}
                                                                        width='36'
                                                                        height='27'
                                                                        alt={title}
                                                                        loading='lazy'
                                                                    />
                                                                </span>
                                                                {slideTitle}
                                                            </h3>
                                                            <p className={styles.slideContent}>{slideDescription}</p>
                                                        </div>
                                                    </>
                                                </Link>
                                            )
                                        })
                                    ) : (
                                        <Link to={slidePage.uri} key={slidePage.id} className={styles.slideLink}>
                                            <>
                                                {slideBigImage ? (
                                                    <GatsbyImage image={slideBigImage} alt={title} />
                                                ) : (
                                                    <img
                                                        alt='image placeholder'
                                                        role='presentation'
                                                        src="data:image/svg+xml;charset=utf-8,%3Csvg height='555' width='390' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                                    />
                                                )}
                                                <div className={styles.slideContentWrapper}>
                                                    <h3 className={styles.slideTitle}>
                                                        <span className={styles.flag}>
                                                            <img
                                                                src={flagImage.default}
                                                                width='36'
                                                                height='27'
                                                                alt={title}
                                                                loading='lazy'
                                                            />
                                                        </span>
                                                        {title}
                                                    </h3>
                                                    <p className={styles.slideContent}>{description}</p>
                                                </div>
                                            </>
                                        </Link>
                                    )}
                                </div>
                            )
                        })}
                    </Carousel>
                ) : (
                    <div>
                        {sliderItems.map(slide => {
                            const title = slide[0].residencySlideTitle
                            const description = slide[0].residencySlideDescription
                            const slidePage = slide[0].residencySlideLink

                            if (!slidePage) return

                            const flagCode = slidePage.countryFlagIcon?.flag
                                ? slidePage.countryFlagIcon.flag.split('.')[0]
                                : null
                            const flagImage = flagCode ? require(`../../assets/images/flags/${flagCode}.svg`) : null
                            const slideImage =
                                slidePage.featuredImage && slidePage.featuredImage.node
                                    ? getImage(
                                          slidePage.featuredImage.node.localFile.childrenImageSharp[0].gatsbyImageData
                                      )
                                    : null

                            return (
                                <div className={styles.slideInner} key={slidePage.id}>
                                    {slide.length > 1 ? (
                                        slide.map(innerSlide => {
                                            const slideTitle = innerSlide.residencySlideTitle
                                            const slideDescription = innerSlide.residencySlideDescription
                                            const innerSlidePage = innerSlide.residencySlideLink

                                            if (!innerSlidePage) return

                                            const flagCode = slidePage.countryFlagIcon?.flag
                                                ? slidePage.countryFlagIcon.flag.split('.')[0]
                                                : null
                                            const flagImage = flagCode
                                                ? require(`../../assets/images/flags/${flagCode}.svg`)
                                                : null
                                            const innerSlideImage =
                                                innerSlidePage.featuredImage && innerSlidePage.featuredImage.node
                                                    ? innerSlidePage.featuredImage.node.localFile.childrenImageSharp[0]
                                                          .gatsbyImageData
                                                    : null

                                            return (
                                                <Link
                                                    to={innerSlidePage.uri}
                                                    key={innerSlidePage.id}
                                                    className={styles.slideLink}
                                                >
                                                    <>
                                                        {innerSlideImage ? (
                                                            <GatsbyImage image={innerSlideImage} alt={slideTitle} />
                                                        ) : (
                                                            <img
                                                                alt='image placeholder'
                                                                role='presentation'
                                                                src="data:image/svg+xml;charset=utf-8,%3Csvg height='270' width='390' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                                            />
                                                        )}
                                                        <div className={styles.slideContentWrapper}>
                                                            <h3 className={styles.slideTitle}>
                                                                {flagImage && (
                                                                    <span className={styles.flag}>
                                                                        <img
                                                                            src={flagImage.default}
                                                                            width='36'
                                                                            height='27'
                                                                            alt={title}
                                                                            loading='lazy'
                                                                        />
                                                                    </span>
                                                                )}
                                                                {slideTitle}
                                                            </h3>
                                                            <p className={styles.slideContent}>{slideDescription}</p>
                                                        </div>
                                                    </>
                                                </Link>
                                            )
                                        })
                                    ) : (
                                        <Link to={slidePage.uri} key={slidePage.id} className={styles.slideLink}>
                                            <>
                                                {slideImage ? (
                                                    <GatsbyImage image={slideImage} alt={title} />
                                                ) : (
                                                    <img
                                                        alt='image placeholder'
                                                        role='presentation'
                                                        src="data:image/svg+xml;charset=utf-8,%3Csvg height='270' width='390' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                                    />
                                                )}
                                                <div className={styles.slideContentWrapper}>
                                                    <h3 className={styles.slideTitle}>
                                                        {flagImage && (
                                                            <span className={styles.flag}>
                                                                <img
                                                                    src={flagImage.default}
                                                                    width='36'
                                                                    height='27'
                                                                    alt={title}
                                                                    loading='lazy'
                                                                />
                                                            </span>
                                                        )}
                                                        {title}
                                                    </h3>
                                                    <p className={styles.slideContent}>{description}</p>
                                                </div>
                                            </>
                                        </Link>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
                {!md && residencyLink.url && (
                    <Link to={residencyLink.url} className='global-button global-button--rounded'>
                        {residencyLink.title}
                    </Link>
                )}
            </div>
        </section>
    )
}

export default ResidencyCarousel
