import React from 'react'

import Hero from '../components/Hero'
import Reviews from '../components/Reviews'
import Benefits from '../components/Benefits'
import RecentPosts from '../components/RecentPosts'
import EstimateCost from '../components/EstimateCost'
import ResidencyCarousel from '../components/ResidencyCarousel'
import RegistrationCarousel from '../components/RegistrationCarousel'

const HomePage = ({ data }) => {
    const { heroSection, residencyRegistration, businessRegistration, costEstimate, benefits } = data

    return (
        <div className='global-container'>
            <Hero data={heroSection} />
            <ResidencyCarousel data={residencyRegistration} />
            <RegistrationCarousel data={businessRegistration} />
            <EstimateCost data={costEstimate} />
            <Benefits data={benefits} />
            <RecentPosts />
            <Reviews />
        </div>
    )
}

export default HomePage
