// extracted by mini-css-extract-plugin
export var wrapper = "residency-module--wrapper--Ehxwt";
export var tabs = "residency-module--tabs--4Go3-";
export var list = "residency-module--list--Q9own";
export var listItem = "residency-module--listItem--jTjgP";
export var itemMore = "residency-module--itemMore--9GZRP";
export var itemTitle = "residency-module--itemTitle--jhb30";
export var itemContent = "residency-module--itemContent--u6FNK";
export var imgWrapper = "residency-module--imgWrapper--F2n3n";
export var flag = "residency-module--flag--p4IGa";
export var content = "residency-module--content--oU5-Z";