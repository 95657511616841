import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

import * as styles from './faq.module.scss'

const FAQ = props => {
    const {
        faqRightSide: { faqImage },
        faqLeftSide: { faqSectionTitle, faqList },
    } = props.data
    const sectionImage = faqImage ? getImage(faqImage.localFile) : null
    const { Panel } = Collapse

    return (
        <section className={styles.wrapper + ' global-full-width'}>
            <div className='global-container'>
                <div className={styles.column}>
                    <h2
                        data-sal='slide-up'
                        data-sal-delay='250'
                        data-sal-duration='1800'
                        data-sal-easing='ease-out-back'
                        className='global-title-h2'
                        dangerouslySetInnerHTML={{ __html: faqSectionTitle }}
                    />
                    <Collapse
                        ghost
                        defaultActiveKey={['0']}
                        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                        className='site-collapse-custom-collapse'
                    >
                        {faqList.map((item, index) => {
                            return (
                                <Panel header={item.question} key={index}>
                                    <p
                                    // data-sal='slide-up'
                                    // data-sal-delay='350'
                                    // data-sal-duration='1800'
                                    // data-sal-easing='ease-out-back'
                                    >
                                        {item.faqAnswer}
                                    </p>
                                </Panel>
                            )
                        })}
                    </Collapse>
                </div>
                <div
                    data-sal='slide-up'
                    data-sal-delay='350'
                    data-sal-duration='1800'
                    data-sal-easing='ease-out-back'
                    className={styles.column}
                >
                    {typeof sectionImage === 'object' && sectionImage !== null && (
                        <GatsbyImage image={sectionImage} alt={faqSectionTitle} />
                    )}
                </div>
            </div>
        </section>
    )
}

export default FAQ
