import React from 'react'
import loadable from '@loadable/component'

import Hero from '../components/Hero'

const Calculator = loadable(() => import('../components/Calculator'))
// const EstimateCost = loadable(() => import('../components/EstimateCost'))

const PricePage = ({ data, pageContext, location }) => {
    const { title, heroSection, costEstimate } = data
    const { breadcrumb } = pageContext

    return (
        <div className='global-container'>
            <Hero data={heroSection} breadcrumb={breadcrumb} breadcrumbLabel={title} />
            <Calculator {...data} pageContext={pageContext} location={location} />
        </div>
    )
}

export default PricePage
