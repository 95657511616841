import React from 'react'

import Hero from '../components/Hero'
import RecentPosts from '../components/RecentPosts'
import ContentSection from '../components/ContentSection'
import EstimateCost from '../components/EstimateCost'

const SitizenInnerPage = ({ data, pageContext, location }) => {
    const { heroSection, costEstimate, recentPosts, title } = data
    const { breadcrumb } = pageContext

    return (
        <div className='global-container'>
            <Hero data={heroSection} breadcrumb={breadcrumb} breadcrumbLabel={title} />
            <ContentSection {...data} withSidebar />
            <EstimateCost data={costEstimate} />
            {recentPosts && (
                <RecentPosts
                    title={recentPosts.recentTitle}
                    list={recentPosts.recentList}
                    link={recentPosts.recentButton}
                    withBackground
                />
            )}
        </div>
    )
}

export default SitizenInnerPage
