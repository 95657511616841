import React from 'react'

import Hero from '../components/Hero'
import ResidencyPages from '../components/ResidencyPages'
import RecentPosts from '../components/RecentPosts'
import EstimateCost from '../components/EstimateCost'
import About from '../components/About'
import FAQ from '../components/FAQ'

const SitizenPage = ({ data, pageContext, location }) => {
    const { heroSection, aboutSection, costEstimate, residencePages, recentPosts, faq, title } = data
    const { breadcrumb } = pageContext

    return (
        <div className='global-container'>
            <Hero data={heroSection} breadcrumb={breadcrumb} breadcrumbLabel={title} />
            <About data={aboutSection} />
            <ResidencyPages data={residencePages} location={location} />
            <EstimateCost data={costEstimate} />
            <FAQ data={faq} />
            {recentPosts && (
                <RecentPosts
                    title={recentPosts.recentTitle}
                    list={recentPosts.recentList}
                    link={recentPosts.recentButton}
                />
            )}
        </div>
    )
}

export default SitizenPage
