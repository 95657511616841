import React, { useEffect, useRef } from 'react'

import * as styles from './contacts.module.scss'

const Contacts = props => {
    const {
        data: { officesTitle, officesDescription, officesList },
        activeIndex,
        pointClickHandler,
    } = props
    const wrapperRef = useRef(null)

    const useOutsideClickHandler = ref => {
        useEffect(() => {
            const handleClickOutside = event => {
                if (ref.current && !ref.current.contains(event.target)) {
                    pointClickHandler()
                }
            }

            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }

    useOutsideClickHandler(wrapperRef)

    return (
        <section className={styles.wrapper} id='section'>
            <h2 className='global-title-h2'>{officesTitle}</h2>
            <div
                className={styles.description + ' global-text'}
                dangerouslySetInnerHTML={{ __html: officesDescription }}
            />
            {officesList.length > 0 && (
                <ul className={styles.list} ref={wrapperRef}>
                    {officesList.map((item, index) => {
                        return (
                            <li
                                key={item.officesItemTitle}
                                className={activeIndex === index ? styles.activeItem : styles.item}
                                onClick={() => pointClickHandler(item, index)}
                            >
                                <h3 className={styles.item__title}>{item.officesItemTitle}</h3>
                                <div
                                    className={styles.item__content}
                                    dangerouslySetInnerHTML={{ __html: item.officesItemContent }}
                                />
                            </li>
                        )
                    })}
                </ul>
            )}
        </section>
    )
}

export default Contacts
