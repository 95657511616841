import React from 'react'
import * as styles from './benefits.module.scss'

const Benefits = props => {
    const { benefitsTitle, list } = props.data

    return (
        <section className={styles.wrapper}>
            <h2
                className='global-title-h3'
                data-sal='slide-up'
                data-sal-delay='250'
                data-sal-duration='1500'
                data-sal-easing='ease-out-back'
            >
                {benefitsTitle}
            </h2>
            {list.length > 0 && (
                <ul className={styles.list}>
                    {list.map((item, index) => {
                        return (
                            <li
                                key={item.title}
                                className={styles.item}
                                data-sal='slide-up'
                                data-sal-delay={200 + index * 100}
                                data-sal-duration='1500'
                                data-sal-easing='ease-out-back'
                            >
                                <h3 className={styles.itemTitle}>{item.title}</h3>
                                <div className={styles.itemDescription}>{item.description}</div>
                            </li>
                        )
                    })}
                </ul>
            )}
        </section>
    )
}

export default Benefits
