import React, { useState, useEffect } from 'react'
import loadable from '@loadable/component'

import Hero from '../components/Hero'
import Contacts from '../components/Contacts'

const Map = loadable(() => import('../components/Map'))
const EstimateCost = loadable(() => import('../components/EstimateCost'))

const ContactsPage = ({ data, pageContext, location }) => {
    const [activeIndex, setActiveIndex] = useState(null)
    const [officesPoints, setOfficesPoints] = useState([])
    const { title, heroSection, offices, costEstimate } = data
    const { breadcrumb } = pageContext

    useEffect(() => {
        setAllOfficesPoints()
    }, [])

    const setAllOfficesPoints = () => {
        let places = []

        offices.officesList.forEach(item => {
            places.push({
                name: item.officesItemTitle,
                latitude: item.officesItemLocation.officesItemLocationLatitude,
                longitude: item.officesItemLocation.officesItemLocationLongitude,
            })
        })

        setOfficesPoints(places)
        setActiveIndex(null)
    }

    const onPointClick = (office, index) => {
        if (!office) {
            return setAllOfficesPoints()
        }

        const activePoint = [
            {
                name: office.officesItemTitle,
                latitude: office.officesItemLocation.officesItemLocationLatitude,
                longitude: office.officesItemLocation.officesItemLocationLongitude,
            },
        ]

        setOfficesPoints(activePoint)
        setActiveIndex(index)
    }

    return (
        <div className='global-container'>
            <Hero data={heroSection} breadcrumb={breadcrumb} breadcrumbLabel={title} />
            <Contacts data={offices} activeIndex={activeIndex} pointClickHandler={onPointClick} />
            <Map places={officesPoints} />
            <EstimateCost data={costEstimate} type='contact' />
        </div>
    )
}

export default ContactsPage
