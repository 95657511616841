import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid, Carousel } from 'antd'

import ArrowRightIcon from '../../assets/svg/arrow-right.inline.svg'
import * as styles from './registration.module.scss'

const RegistrationCarousel = props => {
    const { useBreakpoint } = Grid
    const { md, sm } = useBreakpoint()
    const { registrationTitle, registrationDescription, registrationLink, registrationItems } = props.data

    return (
        <section className={styles.wrapper}>
            <h2 className='global-title-h2'>{registrationTitle}</h2>
            <p className='global-text'>{registrationDescription}</p>
            {md && registrationLink.url && (
                <Link to={registrationLink.url} className='global-button global-button--rounded'>
                    {registrationLink.title}
                </Link>
            )}
            {sm ? (
                <Carousel
                    className={styles.slider}
                    arrows={true}
                    draggable={true}
                    slidesToShow={3}
                    slidesToScroll={1}
                    responsive={[
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                    ]}
                >
                    {registrationItems.map(slide => {
                        const title = slide.registrationSlideTitle
                        const description = slide.registrationSlideDescription
                        const slidePage = slide.registrationSlideLink

                        if (!slidePage) return

                        const flagCode = slidePage.countryFlagIcon?.flag
                            ? slidePage.countryFlagIcon.flag.split('.')[0]
                            : null
                        const flagImage = flagCode ? require(`../../assets/images/flags/${flagCode}.svg`) : null
                        const slideImage =
                            slidePage.featuredImage && slidePage.featuredImage.node
                                ? getImage(slidePage.featuredImage.node.localFile)
                                : null

                        return (
                            <div className={styles.slideInner} key={slidePage.id}>
                                <Link to={slidePage.uri}>
                                    <div className={styles.imgWrapper}>
                                        {slideImage ? (
                                            <GatsbyImage image={slideImage} alt={title} />
                                        ) : (
                                            <img
                                                alt='image placeholder'
                                                role='presentation'
                                                src="data:image/svg+xml;charset=utf-8,%3Csvg height='220' width='380' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                            />
                                        )}
                                        {flagImage && (
                                            <span className={styles.flag}>
                                                <img
                                                    src={flagImage.default}
                                                    alt={title}
                                                    width='40'
                                                    height='30'
                                                    loading='lazy'
                                                />
                                            </span>
                                        )}
                                    </div>
                                    <div className={styles.slideContent}>
                                        <h3 className={styles.slideTitle}>{title}</h3>
                                        <p>{description}</p>
                                        <span className={styles.slideMore}>
                                            <span>Подробнее</span>
                                            <ArrowRightIcon />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </Carousel>
            ) : (
                <div>
                    {registrationItems.map(slide => {
                        const title = slide.registrationSlideTitle
                        const description = slide.registrationSlideDescription
                        const countryName = slide.registrationSlideCountry
                        const slidePage = slide.registrationSlideLink

                        if (!slidePage) return

                        const flagCode = slidePage.countryFlagIcon?.flag
                            ? slidePage.countryFlagIcon.flag.split('.')[0]
                            : null
                        const flagImage = flagCode ? require(`../../assets/images/flags/${flagCode}.svg`) : null
                        const slideImage =
                            slidePage.featuredImage && slidePage.featuredImage.node
                                ? getImage(slidePage.featuredImage.node.localFile)
                                : null

                        return (
                            <div className={styles.slideInner} key={slidePage.id}>
                                <Link to={slidePage.uri}>
                                    <div className={styles.imgWrapper}>
                                        {slideImage ? (
                                            <GatsbyImage image={slideImage} alt={title} />
                                        ) : (
                                            <img
                                                alt='image placeholder'
                                                role='presentation'
                                                src="data:image/svg+xml;charset=utf-8,%3Csvg height='220' width='380' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                            />
                                        )}
                                        <p className={styles.imgContent}>
                                            {flagImage && (
                                                <span className={styles.flag}>
                                                    <img
                                                        src={flagImage.default}
                                                        alt={title}
                                                        width='40'
                                                        height='30'
                                                        loading='lazy'
                                                    />
                                                </span>
                                            )}
                                            {countryName && <span>{countryName}</span>}
                                        </p>
                                    </div>
                                    <div className={styles.slideContent}>
                                        <h3 className={styles.slideTitle}>{title}</h3>
                                        <p>{description}</p>
                                        <span className={styles.slideMore}>
                                            <span>Подробнее</span>
                                            <ArrowRightIcon />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            )}
            {!md && registrationLink.url && (
                <Link to={registrationLink.url} className='global-button global-button--rounded'>
                    {registrationLink.title}
                </Link>
            )}
        </section>
    )
}

export default RegistrationCarousel
